::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}