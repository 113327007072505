@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#check-box-1:checked~.check-1 {
  @apply text-opacity-100;
}

.custom-drawer .ant-drawer-body {
  padding: 0;
}

.custom-menu {
  padding: 0;
  margin: 0;
}

#root, .ant-layout {
  min-height: 100vh; /* This makes the layout take at least the full height of the viewport */
  display: flex;
  flex-direction: column;
}

.ql-snow .ql-tooltip {  transform: translateX(200px) !important;   background-color: #f8f8f8;   border: 1px solid #ccc;   box-shadow: 0px 0px 5px rgba(0,0,0,0.2);   color: #444; }  .ql-snow .ql-tooltip.ql-editing .ql-action::after {   content: 'Save'; }  .ql-snow .ql-tooltip.ql-editing .ql-remove::before {   content: 'Cancel'; }  .ql-snow .ql-tooltip a.ql-action::after {   content: 'Done'; }  .ql-snow .ql-tooltip a.ql-remove::before {   content: 'Remove'; }